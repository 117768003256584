/* CSS PARA EL PROYECTO LOCAL */
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	border-color: #FFFFFF00 !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
	background-color: #C9C9C9B9 !important;
	backdrop-filter: blur(10px);

}


/* CSS PARA LA BUILD */
.css-igs3ac {
	border-color: #FFFFFF00 !important;
}

.css-pwxzbm {
	background-color: #C9C9C9B9 !important;
	backdrop-filter: blur(10px);

}