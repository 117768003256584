/* --------------- -----------------Colores:
 ----------------- -----------------Principal: #EAC474
 ----------------- -----------------Secundario: #C9C9C9 // #B4B4B4
 ----------------- -----------------Negro: #2E2E2E
 ----------------- -----------------Rojo: #e30505 */



.fade-in {
	animation: load-page 0.5s linear;
}

.fade-in-2 {
	animation: load-page 0.25s linear;
}

@keyframes load-page {

	/* Define your animation keyframes here */
	/* For example: */
	0% {
		opacity: 0;
		filter: brightness(0%);
	}

	100% {
		opacity: 1;
		filter: brightness(100%);
	}
}

@keyframes slideInFromTop {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(0);
	}
}

.slide-top {
	animation: 1s ease-out 0s 1 slideInFromTop;
}


.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #2E2E2E;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.screen {
	min-height: 100vh;
}

.main-background {
	background-image: url(./media/background1.png);
	background-size: 100% auto;
	/* Para cubrir el ancho completo y repetir verticalmente */
	background-repeat: repeat-y;
	/* Solo para estar seguro de que se repita verticalmente */
	min-height: 100vh;
}

.flex-center {
	display: flex;
	justify-content: center;
}

.button-menu {
	transform: skew(-20deg);
	/* margin-right: 0.51em; */
	background-color: rgb(255, 194, 113);
	padding: 0.5em;
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	margin-top: 0.5em;
	margin-right: 1.5em;
	min-width: 16em;
	transition: 50ms;
	text-align: center;
	backdrop-filter: blur(10px);
	cursor: pointer;
}

.button-menu:hover {
	transform: skew(-20deg);
	cursor: pointer;
	/* margin-right: 0.51em; */
	background-color: rgb(255, 194, 113);
	padding: 0.5em;
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	margin-top: 0.5em;
	margin-right: 1.5em;
	min-width: 16em;
	text-align: center;
	backdrop-filter: blur(10px);
	transition: background-color 50ms;
}

.button-header {
	transform: skew(-20deg);
	/* margin-right: 0.51em; */
	background-color: #eac574;
	padding: 0.5em;
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	margin-top: 0.5em;
	margin-right: 1.5em;
	min-width: 6em;
	transition: 50ms;
	text-align: center;
	backdrop-filter: blur(10px);
	color: #1f1f1f;
	transition: background-color 0.25s;

}

.button-header:hover {
	transform: skew(-20deg);
	margin-right: 0.51em;
	background-color: #EAC474;
	color: #1f1f1f;
	padding: 0.5em;
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	margin-top: 0.5em;
	margin-right: 1.5em;
	min-width: 6em;
	transition: 50ms;
	text-align: center;
	backdrop-filter: blur(10px);
	transition: background-color 0.25s;

}

.button-panel {
	background-color: #432918;
	color: #eac474;
	font-size: 20px !important;
	font-weight: 600;
	padding: 0.2em;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	cursor: pointer;
	user-select: none;
	margin-bottom: 1em;
	min-width: 50%;
	min-height: 1vh;
	max-width: 50%;
	transition-duration: 500ms;
}

.button-panel:hover {
	background-color: #eac474;
	color: #432918;
	font-size: 24;
	font-weight: 600;
	padding: 0.2em;
	border-radius: 4px;
	transition-duration: 500ms;
	display: flex;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
	margin-bottom: 1em;
	min-width: 50%;
	min-height: 1vh;
	max-width: 50%;
}

.header {
	background-color: #2E2E2E;
	color: white;
	min-height: 8vh;
	/* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
	/* margin-bottom: 2em; */
}

.slideInFromTop {
	animation: slideInTop 0.5s ease forwards;
}

.slideInFromBottom {
	animation: slideInBottom 0.5s ease forwards;
}

@keyframes slideInTop {
	from {
		transform: translateY(-80%);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideInBottom {
	from {
		transform: translateY(-90%);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}


.panel {
	width: 90%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
}

.logo {
	display: flex;
	justify-content: center;
	padding: 0.51em;
}

.logo-header {
	max-width: 15em;
}

.return-button {
	padding: 1em;
	border-radius: 4px;
	background-color: #432918;
	max-width: 4em;
	color: #eac474;
	display: flex;
	justify-content: center;
	font-size: 15px;
	margin: 1em;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.return-button:hover {
	padding: 1em;
	border-radius: 4px;
	background-color: #eac474;
	max-width: 4em;
	color: #432918;
	display: flex;
	justify-content: center;
	font-size: 12px;
	margin: 1em;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tramo-card {
	background-color: rgb(165, 165, 165);
	min-width: 12em;
	color: white;
	font-size: 24px;
	display: flex;
	justify-content: center;
	flex-flow: column;
	margin-top: 1em;
	border-radius: 10px;
}

.tramo-container {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: space-between;
	align-items: center;
}

.card-title {
	background-color: rgb(112, 112, 112);
	font-size: 40px;
	min-width: 12em;
	color: white;
	font-size: 24px;
	display: flex;
	justify-content: center;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.card-element {
	font-size: 40px;
	min-width: 12em;
	color: white;
	font-size: 24px;
	display: flex;
	justify-content: center;
}

.select {
	background-color: #C9C9C9 !important;
	min-width: 16em;
	max-width: 16em;
}

.main-background-dark {
	background-color: rgb(46, 46, 46);
	min-height: 100vh;
	animation: load-page 0.5s linear;

}

.flex-container {
	display: flex;
	justify-content: center;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading-spinner {
	width: 50px;
	height: 50px;
	border: 10px solid #e6d5d5;
	/* Light grey */
	border-top: 10px solid #ffffff00;
	/* Black */
	border-radius: 50%;
	animation: spinner 0.8s linear infinite;
}

.spinner {
	margin-top: 20vh;
}

.tiempos-card-container {
	justify-content: flex;
	justify-content: center;
	max-width: 20em;
	min-width: 20em;
	margin-bottom: 1em;
}

.tiempos-card-pair {
	background-color: #b6b6b68f;
	padding: 0.5em;
	display: flex;
	flex-grow: 1;
	font-size: smaller;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	flex-flow: wrap;

}

.no-descalf {
	background-color: white;
}

.tiempos-card-odd {
	background-color: #d4d4d48a;
	padding: 0.5em;
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	font-size: smaller;
	font-weight: 600;
	row-gap: 10px;
	flex-flow: wrap;
	align-items: center;
}

.tramo-title {
	background-color: #eac474;
	font-size: small;
	font-weight: 700;
	padding: 1em;
	display: flex;
	justify-content: left;
	margin-top: 1em;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.provisional {
	background-color: rgba(255, 133, 33, 0.548);
	padding: 0.15em;
	border-radius: 4px;
}

.confirmado {
	background-color: #0a740059;
	padding: 0.15em;
	border-radius: 4px;
}

.tiempos-container {
	margin-bottom: 1em;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dorsal {
	max-width: 8%;
	min-width: 8%;
	margin-left: 5px;
	text-align: center;
}

.dorsal-img {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2em;
	font-weight: 700;
	margin-left: 0.5em;
	border-radius: 4px;
}

.nombre-equipo {
	max-width: 44%;
	text-transform: uppercase;
	/* white-space: nowrap; */
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 44%;
}

.tiempo {
	max-width: 26%;
	min-width: 26%;
	text-align: center;

}

.titlee {
	font-size: small !important;
}

.bx {
	background-color: white !important;
	border-radius: 2px;
	border-color: green !important;
	border: solid 1px;

}

.tiempo-general-container {
	min-width: 100%;
	display: flex;
	justify-content: space-between;
}

.general-elemnt {
	text-align: center;
}

.posicion {
	background-color: #eac474;
	text-align: center;
	font-weight: 900;
	width: 8%;
	height: 20px;
	transform: skew(-20deg);
	/* margin-right: 0.51em; */
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.header-menu-container {
	display: flex;
	justify-content: space-evenly;
}

.buttons-container {
	min-width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.login-card {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: start;
	min-width: 15em;
	max-width: 22em;
	background-color: #eeeeee;
	margin: 0.8em;
	min-height: 5vh;
	font-size: 20px;
	font-weight: 600;
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
}

.login-element {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
}

.login-icon {
	min-width: 100%;
	max-height: 10vh;
	text-align: center;
	padding-top: 1em;
	color: #1f1f1f;
}

.sirk {
	cursor: pointer;
	user-select: none;
	transform: skew(-20deg);
	/* margin-right: 0.51em; */
	background-color: #eac574b9;
	padding: 0.5em;
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin-top: 0.5em;
	margin-right: 1.5em;
	min-width: 12em;
	text-align: center;
	/* transition: 50ms; */
	backdrop-filter: blur(10px);
	cursor: pointer;
	transition: min-width 10.25s, box-shadow 10s, background-color 10s;
}

.sirk:hover {
	background-color: #eac574;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	/* min-width: 16em; */
	transition: min-width 10.25s, box-shadow 10s, background-color 10s;
}

.spacer {
	min-height: 1vh;
}

.edicion-card {
	padding: 1em;
	background-color: #eac474;
	min-width: 80%;
	border-radius: 5px;
	margin: 0.5em;
	font-weight: 700;
	font-size: large;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	cursor: pointer;
}

.componentes-container {
	padding: 1em;
	background-color: #eac474;
	min-width: 80%;
	border-radius: 5px;
	margin: 0.5em;
	font-weight: 700;
	font-size: large;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	cursor: pointer;
}

.edicion-elment {
	margin-left: 3em;
}

.edicon-desplegar {
	display: flex;
	align-items: center;
	color: #353535;
}

.edicon-desplegado {
	display: flex;
	align-items: center;
	color: #353535;
	transition: 500ms;
	transform: rotate(90deg);
}

.desplegable {
	width: 100%;
	background-color: #adadad;
	border-radius: 10px;
	padding: 1em;
}

.desplegable-card {
	display: flex;
	/* justify-content: space-evenly; */
	padding: 1em;
	margin-top: 0.5em;
	background-color: #eac474;
	flex-flow: wrap;
	border-radius: 8px;
	min-width: 10em;
	flex-grow: 1;
	gap: 20px;
}

.desplegable-element {
	min-width: 10%;
	max-width: 10%;
	text-align: center;
	display: flex;
	justify-content: center;
}

.tiempo-card {
	max-width: 100%;
	min-width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	gap: 20px;
	margin-top: 1em;
	border-radius: 6px;
}

.tiempo-element {
	max-width: 100%;
	min-width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	gap: 20px;
	background-color: #b9b9b985;
	margin-top: 0.1em;
	border-radius: 6px;
	padding: 0.5em;
}

.equipos-titulo {
	display: flex;
	justify-content: center;
	color: #dab158;
	transform: skew(-10deg);
	text-shadow: 2px 2px 2px #2e2e2e94;

}

.equipo-card {
	min-width: 90%;
	max-width: 90%;
	/* color: #dab158; */
	/* transform: skew(-10deg); */
	background-color: #B4B4B4;
	border-radius: 4px;
	min-height: 15vh;
	align-items: center;
	justify-content: space-between;
	display: flex;
	margin-top: 1vh;

}

.avatar {
	/* transform: skew(10deg); */
	max-width: 5em;
	min-width: 5em;
	max-height: 5em;
	min-height: 5em;
	border-radius: 4px;
	margin-right: 1em;
	object-fit: cover;
}


.equipo-content {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
}

.equipo-desc {
	max-width: 50%;
	font-size: 11px;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	gap: 0.2em;
	color: #2E2E2E;
}

.flex-even {
	display: flex;
	justify-content: space-evenly;
}

.boton-equipo {
	background-color: #EAC474;
	padding: 0.2em 1em;
	border-radius: 4px;
	cursor: pointer;

}

.icon {
	color: #EAC474;
	margin-right: 10px;

}
/* .persona-content{
	

} */
.persona-container{
	display: flex;
	min-width:33%;
	max-width: 33%;
}
.boton-equipo-info {
	min-width: 4em;
	max-width: 4em;
	background-color: #EAC474;
	display: flex;
	align-items: center;
	padding: 0.1em;
	border-radius: 4px;
	text-align: center;
	cursor: pointer;
	user-select: none;
	margin-right: 0.5em;
}

.sm-icon {
	font-size: 12px !important;
}

.equipo-element {
	min-width: 90%;
	max-width: 90%;
	text-align: left;
	font-size: x-small;
	text-transform: uppercase;


}

.equipo-element-info {
	font-size: small;
	margin-bottom: 0.3em;

}

.detalles-equipo {
	background-color: #35353550;
	min-width: 100%;
	border-radius: 4px;
	min-height: 20vh;
}

.button {
	padding: 0.1em 0.5em;
	border-radius: 5px;
	background-color: #3535355b;
	user-select: none;
	display: flex;
	align-items: center;
	color: #432918;
}

.edit-equipo-card {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 0.5em;
}

.edit-equipo-element {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 1em;
	flex-direction: row;
	align-content: center;
	justify-content: space-between;
	min-width: 100%;
}

.partes-formulario-registro {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	margin-top: 1em;
	min-width: 100%;
}

.text-form {
	min-width: 100%;
}

.field {
	min-width: 16em;
	background-color: #ffffff;
	border-radius: 6px;
	max-width: 16em;
}

.flex-around {
	display: flex;
	justify-content: space-around;
}

.crear-edicion-card {
	display: flex;
	justify-content: space-around;
	background-color: #35353593;
	min-width: 82%;
	border-radius: 5px;
}

.spacer2 {
	min-height: 3em;
}

.componentes-container {
	min-width: 100%;
	background-color: #35353560;
	border-radius: 6px;
	min-height: 6vh;
	margin-top: 0.5em;
	gap: 10px 20px;
}

.componente-card {
	display: flex;
	flex-direction: column;
	justify-content: left;
	max-width: 18em;
	min-width: 18em;
	min-height: 18em;
	max-height: 18em;
	background-color: #dab158;
	border-radius: 6px;
	padding: 1em;
}

.column {
	flex-direction: column;
	justify-content: center;
}

.title {
	min-width: 90%;
	text-align: center;
}

.botones-componente {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5em;
}

.button-component {
	background-color: #432918a9;
	padding: 0.2em 1em;
	color: white;
	border-radius: 5px;
}

.button-component:hover {
	background-color: #432918;
	padding: 0.2em 1em;
	color: white;
	border-radius: 5px;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Opacidad del fondo */
	z-index: 300;
	/* Z-index alto para estar encima de todo */
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(2px);
	transition: opacity 0.3s ease, visibility 0.3s ease;
	overflow-y: auto;
}

.detalles-componente-container {
	background-color: #C9C9C9;
	padding: 1em;
	border-radius: 15px;
	min-width: 50%;
	color: black;
	font-size: medium;
	font-weight: 700;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	align-items: flex-start;
	gap: 2em;
}

.tarjeta_formulario {
	background-color: #C9C9C9;
	padding: 1em;
	border-radius: 15px;
	/* min-width: 50%; */
	color: black;
	font-size: medium;
	font-weight: 700;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;
	gap: 2em;
	/* margin: 1em; */
}

.close {
	text-align: end;
	min-width: 100%;
}


.button-component-dissabled {
	background-color: #888888a9;
	padding: 0.2em 1em;
	color: rgba(255, 255, 255, 0.555);
	border-radius: 5px;
}

.crear-componente-button {
	padding: 0.5em 1em;
	border-radius: 6px;
	background-color: #432918;
	color: white;
}

.crear-componente-button:hover {
	padding: 0.5em 1em;
	border-radius: 6px;
	background-color: #dab158;
	color: white;
}

.button-admf {
	border-radius: 6px;
	width: 100%;
	min-width: 13em;
	height: 3em;
	display: flex;
	justify-content: center;
	background-color: #dab158;
	align-items: center;
	margin-top: 1em;
	font-weight: 700;
	font-size: large;
	cursor: pointer;
}

.button-adm {
	border-radius: 6px;
	width: 100%;
	min-width: 13em;
	height: 3em;
	display: flex;
	justify-content: center;
	background-color: #dab158;
	align-items: center;
	margin-top: 1em;
	font-weight: 700;
	font-size: large;
	cursor: pointer;
}

.boton_formulario {
	border-radius: 6px;
	min-width: 10em;
	width: 100%;
	height: 3em;
	display: flex;
	justify-content: center;
	background-color: #dab158;
	align-items: center;
	margin-top: 1em;
	font-weight: 700;
	font-size: medium;
	cursor: pointer;
}

.boton_formulario-off {
	border-radius: 6px;
	min-width: 10em;
	width: 100%;
	height: 3em;
	display: flex;
	justify-content: center;
	background-color: #838383;
	align-items: center;
	margin-top: 1em;
	font-weight: 700;
	font-size: medium;
	cursor: pointer;
}

.info {
	font-size: smaller;
}

.boton_descargar_formulario {
	border-radius: 6px;
	min-width: 10em;
	width: 100%;
	height: 3em;
	display: flex;
	color: white !important;
	text-decoration: none !important;
	justify-content: center;
	background-color: #dab158;
	align-items: center;
	font-weight: 700;
	font-size: medium;
	cursor: pointer;
}

.button-aux {
	min-width: 13em;
	border-radius: 6px;
	height: 3em;
	display: flex;
	justify-content: center;
	background-color: #dab158;
	align-items: center;
	margin-top: 1em;
	font-weight: 700;
	font-size: large;
	cursor: pointer;
}

.button-aux:hover {
	min-width: 13em;
	border-radius: 6px;
	height: 3em;
	display: flex;
	justify-content: center;
	background-color: #dab158a1;
	align-items: center;
	margin-top: 1em;
	font-weight: 700;
	font-size: large;
	cursor: pointer;
}

.button-adm:hover {
	border-radius: 6px;
	width: 100%;
	min-width: 13em;
	height: 3em;
	display: flex;
	justify-content: center;
	background-color: #dab158a1;
	align-items: center;
	margin-top: 1em;
	font-weight: 700;
	font-size: large;
}

.footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: red;
	color: white;
	text-align: center;
}

.header-gest-tiemp {
	border-radius: 14px;
	width: 100%;
	min-width: 13em;
	height: 4em;
	display: flex;
	justify-content: center;
	background-color: #dab158;
	align-items: center;
	margin-top: 1em;
	font-weight: 700;
	font-size: 25px;
}

.tramo-card-null {
	border-radius: 14px;
	width: 100%;
	min-width: 13em;
	height: 3em;
	display: flex;
	justify-content: space-around;
	background-color: #dab158;
	align-items: center;
	margin-top: 0.1em;
	font-weight: 700;
	font-size: 25px;
	flex-flow: wrap;

}

.tramo-card-on {
	border-radius: 14px;
	width: 100%;
	min-width: 13em;
	height: 3em;
	display: flex;
	justify-content: space-around;
	background-color: #49a866;
	align-items: center;
	margin-top: 0.1em;
	font-weight: 700;
	font-size: 25px;
	flex-flow: wrap;

}

.tramo-card-end {
	border-radius: 14px;
	width: 100%;
	min-width: 13em;
	height: 3em;
	display: flex;
	justify-content: space-around;
	background-color: #C9C9C9;
	align-items: center;
	margin-top: 0.1em;
	font-weight: 700;
	font-size: 25px;
	flex-flow: wrap;
}

.tiempo-buton {
	background-color: #2E2E2E;
	color: white;
	padding: 0.2em;
	border-radius: 5px;
	min-width: 6em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	user-select: none;
}

.on {
	background-color: #49a866 !important;
	transition: ease-out 300ms;

}

.on:hover {
	background-color: #59d37f !important;
	transition: ease-out 300ms;
}

.end {
	background-color: #2E2E2E !important;
	transition: ease-out 300ms;

}

.end:hover {
	background-color: #505050 !important;
	transition: ease-out 300ms;

}

.tiempo-ind {
	background-color: #C9C9C9;
	min-width: 100%;
	border-radius: 4px;
	margin-top: 0.2em !important;
	min-height: 4em;
	display: flex;
	justify-content: space-around;
	flex-flow: wrap;
	align-items: center;
	font-weight: 700;
}

.bl {
	background-color: rgb(35, 35, 112);
	color: white;
}

.yw {
	background-color: rgb(180, 127, 11);
	color: white;
}

.gr {
	background-color: rgb(86, 153, 86) !important;
	color: white;

}

.element-aux {
	min-width: 33%;
	text-align: center;
}

.patro-normal {
	max-width: 21em;
	max-height: 10em;
}

.patro-grande {
	max-width: 42em;
	max-height: 20em;
}

.button-redondo {
	padding: 0.5em 0.8em;
	background-color: #dab158;
	border-radius: 100%;
	margin-bottom: 1em;
	cursor: pointer;
	user-select: none;
}

.button-redondo:hover {
	padding: 0.5em 0.8em;
	background-color: #dab158a1;
	border-radius: 100%;
	margin-bottom: 1em;
	cursor: pointer;
	user-select: none;
}

.equipo-card-info {
	border-radius: 10px;
	background-color: #1f1f1f;
	min-width: 80%;
	margin-bottom: 1em;
	text-align: center;
	font-size: medium !important;
}

.evenly {
	display: flex;
	justify-content: space-evenly;
}

.component-card-info {
	font-size: 11px;
	display: flex;
	background-color: #2E2E2E;
	padding: 0.5em;
	margin: 0.5em;
	/* flex-wrap: wrap; */
	gap: 10px;
	text-transform: uppercase;
}

.component-container {
	background-color: #2E2E2E;

}

.component-card-row {
	flex: 1;
	gap: 1;
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	text-align: center;
}

.component-card-row-type2 {
	flex: 1;
	gap: 1;
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	text-align: center;
	/* background-color: red; */
}

.miniatura {
	max-width: 200px;
	min-width: 200px;
	max-height: 200px;
	object-fit: cover;
}

.field-tiempo {
	min-width: 6em;
	max-width: 6em;
	background-color: white;
	border-radius: 6px;
}

/* prueba */

.tiempo-inicio-container {
	display: flex;
	justify-content: center;
	background-color: #9c9c9c;
	flex-flow: wrap;
}

.button-tiempo {
	background-color: rgb(10, 156, 71);
	border-radius: 6px;
	border: 0;
	min-height: 2.5em;
	font-weight: 600;
	color: white;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.button-tiempo:hover {
	background-color: rgb(84, 160, 84);
	border-radius: 6px;
	border: 0;
	min-height: 2.5em;
	font-weight: 600;
	color: white;
}

.button-descalf {
	background-color: rgb(155, 42, 42);
	border-radius: 6px;
	border: 0;
	min-height: 2.5em;
	font-weight: 600;
	color: white;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.button-descalf:hover {
	background-color: rgb(160, 84, 84);
	border-radius: 6px;
	border: 0;
	min-height: 2.5em;
	font-weight: 600;
	color: white;
}

.button-penalizaciones {
	background-color: rgb(175, 113, 42);
	border-radius: 6px;
	border: 0;
	min-height: 2.5em;
	font-weight: 600;
	color: white;
}

.button-penalizaciones:hover {
	background-color: rgb(173, 130, 80);
	border-radius: 6px;
	border: 0;
	min-height: 2.5em;
	font-weight: 600;
	color: white;
}

.descalif {
	background-color: rgb(134, 48, 48);
}

.primerTiempo {
	background-color: rgb(73, 73, 163);
}

.provisional {
	background-color: rgb(196, 148, 17);
}

.confirmado {
	background-color: rgb(26, 131, 70);
}

.penalizado {
	color: rgb(206, 0, 0);
	font-size: medium;
	font-weight: 800;
}

.penalizaciones-card {
	background-color: #353535;
	min-width: 20em;
	min-height: 20em;
	padding: 1em;
	color: white;
	display: flex;
	justify-content: center;
}

.overlay-aux {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Fondo semi-transparente */
	z-index: 100000;
	/* Asegura que esté encima de otros elementos */

	/* Estilos para hacer el contenido scrollable */
	overflow-y: auto;
	/* Permitir scroll vertical si el contenido es más grande que la pantalla */
	padding: 20px;
	/* Añadir un espacio alrededor del contenido */
	box-sizing: border-box;
}


.dateInputContainer {
	min-width: 12em;
	border-radius: 6px
}

.inputDateInput {
	min-height: 2em;
	background-color: #ffffff;
	color: #0f0f0fb0;
	/* max-width: 6em; */
	border-radius: 5px;
	font-size: 25px;
	border-color: #3c3c3b2f;
	font-family: 'Times New Roman', Times, serif;
}


.descalificado {
	background-color: rgba(255, 0, 0, 0.445);
}


/* -------------------------------------------------------------------------------
-----------CSS IVAN------------------------------------------------------------
------------------------------------------------------------------------------- */

img {
	max-width: 100%;
	max-height: 100%;
}

.element-custom {
	padding: 1em;
	color: rgba(0, 0, 0, 0.925) !important;

	text-decoration: none !important;

}

.element-custom:hover {
	padding: 1em;
	color: rgba(0, 0, 0, 0.925) !important;
	text-decoration: none !important;

}

.element-custom-2 {
	/* padding: 1em; */
	color: rgba(0, 0, 0, 0.925) !important;

	text-decoration: none !important;

}

.element-custom-2:hover {
	/* padding: 1em; */
	color: rgba(0, 0, 0, 0.925) !important;
	text-decoration: none !important;

}

.sirk-2 {
	cursor: pointer;
	user-select: none;
	transform: skew(-20deg);
	/* margin-right: 0.51em; */
	background-color: #EAC474;
	padding: 1em;
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin-top: 0.5em;
	margin-right: 1.5em;
	min-width: 12em;
	text-align: center;
	/* transition: 50ms; */
	backdrop-filter: blur(10px);
	transition: min-width 0.25s, background-color 0.25s;
}

.sirk-2:hover {
	transform: skew(-20deg);
	/* margin-right: 0.51em; */
	background-color: #ffd67d;
	/* padding: 1em; */
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	/* margin-top: 0.5em;
	margin-right: 1.5em; */
	/* min-width: 13em; */
	/* transition: 50ms; */
	text-align: center;
	backdrop-filter: blur(10px);
	transition: min-width 0.25s, background-color 0.25s;
}

.sirk {
	cursor: pointer;
	user-select: none;
	transform: skew(-20deg);
	/* margin-right: 0.51em; */
	background-color: #EAC474b9;
	/* padding: 1em; */
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin-top: 0.5em;
	margin-right: 1.5em;
	min-width: 12em;
	text-align: center;
	/* transition: 50ms; */
	backdrop-filter: blur(10px);
	transition: background-color 0.25s, box-shadow 0.25s;
}

.sirk:hover {
	background-color: #EAC474;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	/* min-width: 14em; */
	transition: background-color 0.25s, box-shadow 0.25s, min-width 0.25s;
}

.sirk2 {
	cursor: pointer;
	user-select: none;
	transform: skew(-20deg);
	/* margin-right: 0.51em; */
	background-color: #EAC474b9;
	padding: 1em;
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	margin-top: 0.5em;
	margin-right: 1.5em;
	min-width: 18em;
	text-align: center;
	/* transition: 50ms; */
	backdrop-filter: blur(10px);
	transition: min-width 0.25s, padding 0.25s, background-color 0.25s;
}

.sirk2:hover {
	transform: skew(-20deg);
	/* margin-right: 0.51em; */
	background-color: rgb(255, 194, 113);
	/* padding: 1.5em; */
	font-size: 14px;
	font-weight: 900;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	margin-top: 0.5em;
	margin-right: 1.5em;
	/* min-width: 16em; */
	/* transition: 50ms; */
	text-align: center;
	backdrop-filter: blur(10px);
	transition: min-width 0.25s, padding 0.25s, background-color 0.25s;
}



.manu-container-custom {
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	align-items: center;
}

.flex-horizontal {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.flex-vertical {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.video-fondo {
	/* margin-bottom: 3em; */
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	min-height: 100vh;
	/* background: url('https://www.jcinformatics.com/media/video.mp4') no-repeat center center fixed !important; */
	/* background-size: cover !important; */
}


h1,
h2,
h3,
strong {
	color: #EAC474;
}

/* .video-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	z-index: -1;
} */

.content-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.overlay-crear-equipo {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Fondo semi-transparente */
	z-index: 1000;
	/* Asegura que esté encima de otros elementos */

	/* Estilos para hacer el contenido scrollable */
	overflow-y: auto;
	/* Permitir scroll vertical si el contenido es más grande que la pantalla */
	padding: 20px;
	/* Añadir un espacio alrededor del contenido */
	box-sizing: border-box;
	/* Incluir el padding en el ancho y alto total del elemento */
}


.video-background {
	position: relative;
	width: 100%;
	height: 100vh;
	/* Ajusta la altura según tus necesidades */
	overflow: hidden;
	z-index: 0;
}

/* Estilos para el video */
.react-player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 10vh;
}

/* Estilos para el contenido adicional */
.content {
	position: relative;
	z-index: 1 !important;
	/* Asegura que el contenido aparezca encima del video */
	text-align: center;
	/* padding: 20px; */
}

.reglamentos {
	color: #2E2E2E;
	text-align: left;
	text-wrap: balance;
}


.selec-idioma {
	position: fixed;
	top: 0;
	/* bottom: 0; */
	/* right: 0; */
	z-index: 1000;
}

.select-idioma-2 {
	/* border: none !important; */
	border-radius: 50px !important;
	border-color: #FFFFFF00 !important;
	background-color: red !important;
}


.dark {
	transition: 0.5s;
	filter: brightness(95%);
}

.dark-2 {
	transition: 0.5s;
	filter: brightness(55%);
}

.idiomas {
	border: 0px !important;
}

.css-1ex1afd-MuiTableCell-root {
	border-bottom: 1px solid #2E2E2E !important;
}

.css-1ygcj2i-MuiTableCell-root {
	border-bottom: 0px !important;
}

.boton-menu {
	background-color: #EAC474;
	border-radius: 4px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	text-align: center;
	backdrop-filter: blur(10px);
}

.table {
	border-collapse: separate;
	border-spacing: 10px;

}

.top {
	position: fixed;
	/* top: 0; */
	/* bottom: 0; */
	/* right: 0; */
	z-index: 10000;
}

.top-2 {
	position: fixed;
	top: 0;
	/* bottom: 0; */
	/* right: 0; */
	left: 1;
	z-index: 10000;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
	border-radius: 15px !important;
}

.css-uhb5lp {
	border-radius: 15px !important;
}

.pointer {
	cursor: pointer;
}

.cuenta-atras {
	padding: 0em 2.5em;
	background-color: #3e2e1f;
	border-radius: 10px;
	border: #EAC474 solid 2px;
	color: #EAC474;
	font-weight: 700;
	/* font-size: 1.2em; */
}



/* -------------------------------------------------------------------------------
-------------------------------------------------------------------------------
------------------------------------------------------------------------------- */